<script>
import { mapActions, mapState } from "vuex";
import uplineSelector from "./UplineSelector.vue";
import dayjs from "dayjs";
import {
  mdiContentSave,
  mdiMenu,
  mdiSend,
  mdiClose,
  mdiAccount,
  mdiAttachment,
  mdiDeleteForever,
  mdiCheck,
  mdiMagnify,
  mdiPlus,
  mdiHelp,
  mdiPen,
  mdiCalendar,
  mdiPlaneCar,
  mdiCar,
  mdiMapSearch,
  mdiCloudDownloadOutline,
  mdiEye,
} from "@mdi/js";

// import toolbarMenu from "@/constants/toolbarmenu";
import { validationMixin } from "vuelidate";
import debounce from "lodash/debounce";
import introJS from "intro.js/intro.js";
import "intro.js/minified/introjs.min.css";
import { http } from "@/http/http";

const createHelper = introJS();

const {
  required,
  requiredIf,
  minLength,
  maxLength,
} = require("vuelidate/lib/validators");

export default {
  name: "create-general-memo-modal",
  components: {
    uplineSelector,
  },
  data() {
    // const srcs = {
    //   1: "https://image.flaticon.com/icons/png/128/709/709722.png"
    // };
    return {
      iconSave: mdiContentSave,
      iconSubmit: mdiSend,
      iconMenu: mdiMenu,
      iconClose: mdiClose,
      iconUser: mdiAccount,
      iconAttachment: mdiAttachment,
      iconDelete: mdiDeleteForever,
      iconCheck: mdiCheck,
      iconSearch: mdiMagnify,
      iconAdd: mdiPlus,
      iconHelp: mdiHelp,
      iconEdit: mdiPen,
      iconTgl: mdiCalendar,
      iconPlaneCar: mdiPlaneCar,
      iconMapSearch: mdiMapSearch,
      iconCar: mdiCar,
      iconDownload: mdiCloudDownloadOutline,
      iconEye: mdiEye,
      ////////////////
      overlay: false,
      editorIsWorking: false,
      showUplineDialog: false,
      axiosProcessing: false,
      karyawanLoading: false,
      bagianLoading: false,
      detailLoading: false,
      rombonganLoading: false,
      lokasiLoading: false,
      qSearchKaryawan: "",
      qSearchLokasi: null,
      lastQRombongan: "",
      daftarKaryawan: [],
      listBagian: [],
      selectedPic: null,
      perpanjangan: false,
      qSearchOriginal: "",
      listSppdInduk: [],
      oriIsLoading: false,
      canChangeSppdInduk: false,
      //////////////////
      jenisPerjalanan: [
        { id: 1, value: "dalam_wilayah", title: "Dalam Wilayah" },
        { id: 2, value: "luar_wilayah", title: "Luar Wilayah" },
        { id: 3, value: "luar_negeri", title: "Luar Negeri" },
      ],
      transportDiTujuan: [
        { id: 1, value: "rental", title: "Rental" },
        { id: 2, value: "online", title: "Online" },
        { id: 3, value: "tidak_ada", title: "Tidak Ada" },
        { id: 4, value: "pribadi", title: "Kendaraan Pribadi" },
      ],
      gradeList: [
        "BOD",
        "BOD-1",
        "BOD-2",
        "BOD-3",
        "BOD-4",
        "BOD-5",
        "BOD-6",
        "NN",
      ],
      statusKaryawan: [
        "DEKOM",
        "IKBI",
        "Komite/Sekdekom",
        "Konsultan",
        "Magang",
        "PKWT/OS",
        "Tamu Perusahaan",
      ],
      transportList: [
        "Pesawat Terbang (Company Paid)",
        "Kereta Api (Company Paid)",
        "Bus (Company Paid)",
        "Kapal Laut (Company Paid)",
        "Mobil (Company Paid)",
        "Booking Mandiri (Reimburse)",
        "Kendaraan Pribadi (Reimburse)",
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 70000)
        .toISOString()
        .substr(0, 10),
      menuTglBerangkat: false,
      menuTglKembali: false,
      descriptionLimit: 60,
      entriesPic: [],
      entriesLokasi: [],
      isLoading: false,
      // selectedKaryawan: null,
      selectedLokasi: null,
      karyawanDivisi: [],
      karyawanRombongan: [],
      rombonganOrganiks: [
        { loading: false, selected: null, entries: [], search: "" }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
      ],
      rombonganNonOrganiks: [
        { nama: null, grade: null, status_karyawan: null }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
      ],
      sendiri: true,
      tipeKrani: ["KRANI", "SEKRETARIS_KRANI", "SEKRETARIS_DIRSEVP"],
      form: {
        jenis_perjalanan: "",
        tujuan_dinas_detail: null,
        rombongan_organik: false,
        rombongan_non_organik: false,
        selected_pic: this.username,
        nama_provinsi: null,
        keperluan_dinas: "",
        transport: null,
        transport_lokasi_dinas: null,
        tanggal_kembali: null,
        tanggal_berangkat: null,
        attachment: null,
        bagian_inisiator: null,
      },
      serverAttachment: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      jenis_perjalanan: { required },
      tujuan_dinas_detail: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(100),
      },
      selected_pic: {
        required: requiredIf(function () {
          return !this.sendiri;
        }),
      },
      nama_provinsi: {
        required: requiredIf(function () {
          return this.form.jenis_perjalanan !== "luar_negeri";
        }),
      },
      keperluan_dinas: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(200),
      },
      transport: { required },
      transport_lokasi_dinas: { required },
      tanggal_kembali: { required },
      tanggal_berangkat: { required },
      bagian_inisiator: { required },
    },
  },
  computed: {
    ...mapState("resources", ["memoRecipient", "dirSevps"]),
    ...mapState("draft", [
      "draftMode",
      "filterParams",
      "memoToReply",
      "draftIsSaved",
    ]),
    ...mapState("user", ["currentUser"]),
    ...mapState(["helpGroupRecipientDialog", "tipToHide"]),
    username() {
      if (!this.currentUser) return "";
      return this.currentUser.username;
    },
    selectedOrgaNik() {
      // if (!this.rombonganOrganiks || !this.rombonganOrganiks.length < 1) return '';
      return this.rombonganOrganiks
        .map((item) => {
          if (item.selected && item.selected.nik_sap) {
            return item.selected.nik_sap;
          } else {
            return ""; // Atau berikan nilai default jika diperlukan, seperti 'N/A'
          }
        })
        .filter((nik) => nik)
        .join(",");
    },
    selectedNonOrgaNik() {
      return this.rombonganNonOrganiks
        .map((item) => {
          if (item && item.nama) {
            return item.nama;
          } else {
            return ""; // Atau berikan nilai default jika diperlukan, seperti 'N/A'
          }
        })
        .filter((nik) => nik)
        .join(",");
    },
    nikIsDuplicate() {
      if (this.selectedOrgaNik === "") return false;
      if (!this.form.selected_pic) return false;
      return this.selectedOrgaNik.includes(this.form.selected_pic);
    },
    memberIsDuplicate() {
      if (this.selectedOrgaNik === "") return false;
      const elements = this.selectedOrgaNik
        .split(",")
        .map((item) => item.trim());
      const uniqueElements = new Set(elements);
      return uniqueElements.size !== elements.length;
    },
    memberIsUser() {
      if (!this.sendiri) return false;
      if (this.selectedOrgaNik === "") return false;
      return this.selectedOrgaNik.includes(this.username);
    },
    itemsPic() {
      if (this.entriesPic.length == undefined) return null;
      return this.entriesPic.map((entry) => {
        const Description =
          entry.nama.length > 80
            ? entry.nama.slice(0, this.descriptionLimit) + "..."
            : entry.nama;
        return Object.assign({}, entry, { Description });
      });
    },
    canRentACar() {
      //jangan dihapus tapi dinonaktifkan; mana tau berubah lg
      // if (this.form.transport_lokasi_dinas !== 'rental') return true;
      // if (this.sendiri) {
      //   if (!this.selectedOrgaNik && !this.selectedNonOrgaNik) return false
      //   if (this.selectedOrgaNik.length >= 7) return true
      //   if (this.selectedNonOrgaNik.length >= 5) return true
      // } else {
      //   if (!this.selectedPic) return false
      //   if (this.selectedPic.length > 0) {
      //     if (!this.selectedOrgaNik && !this.selectedNonOrgaNik) return false
      //   }
      //   if (this.selectedOrgaNik.length >= 7) return true
      //   if (this.selectedNonOrgaNik.length >= 5) return true
      // }

      return true;
    },
    itemsLokasi() {
      if (this.entriesLokasi.length == undefined) return null;
      return this.entriesLokasi.map((entry) => {
        const Description =
          entry.nama_kota.length > 80
            ? entry.nama_kota.slice(0, this.descriptionLimit) + "..."
            : entry.nama_kota;

        return Object.assign({}, entry, { Description });
      });
    },
    editSppd: {
      get() {
        return this.$store.state.editSppd;
      },
      set(val) {
        this.formReset();
        createHelper.exit();
        this.cleanupIntroJs();
        this.$store.commit("SET_EDIT_SPPD", val);
      },
    },

    editorDisabled() {
      if (!this.form.kepada || !this.form.hal) return true;
      if (this.form.kepada.length > 0 && this.form.hal.length > 9) return false;
      return true;
    },
    dateIsValid() {
      if (!this.form.tanggal_berangkat || !this.form.tanggal_kembali)
        return true;
      const tanggalPertama = dayjs(this.form.tanggal_berangkat);
      const tanggalKedua = dayjs(this.form.tanggal_kembali);
      return tanggalPertama.isBefore(tanggalKedua);
    },
  },

  watch: {
    editSppd(val) {
      //empty oldData everytime modal is showing;
      if (val && val.status === true) {
        this.rombonganOrganiks = [];
        this.rombonganNonOrganiks = [];
        this.getAllKaryawanDivisi();
        this.getAllBagian();
        this.getSppdDetail();
      }
    },
    selectedPic(val) {
      if (
        this.karyawanLoading ||
        this.bagianLoading ||
        this.detailLoading ||
        this.rombonganLoading ||
        this.lokasiLoading
      )
        return;

      if (val) {
        this.form.selected_pic = val.nik_sap;
      } else {
        this.form.selected_pic = "";
      }
    },
    selectedLokasi(val) {
      if (val) {
        this.form.nama_provinsi = val.nama_kota;
      } else {
        this.form.nama_provinsi = "";
      }
    },
    "form.rombongan_non_organik"(val) {
      if (val === null)
        this.rombonganNonOrganiks = [
          { nama: null, grade: null, status_karyawan: null }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
        ];
    },
    "form.rombongan_organik"(val) {
      if (val === null)
        this.rombonganOrganiks = [
          { loading: false, selected: null, entries: [], search: "" }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
        ];
    },
    "form.original_sppd"(val) {
      if (val === null) return;
      if (!this.canChangeSppdInduk) return;
      if (val.id) {
        this.getSppdInduk(val.id);
      }
    },
    qSearchOriginal: {
      handler: debounce(function (val) {
        if (!val) return;
        this.getOriginalSppd(val);
      }, 500),
      immediate: true,
    },
    qSearchKaryawan: {
      handler: debounce(function (val) {
        if (!val) return;
        this.getDaftarKaryawan(val);
      }, 500),
      immediate: true,
    },
    qSearchLokasi: {
      handler: debounce(function (val) {
        if (!val) return;
        this.getDaftarLokasi(val);
      }, 500),
      immediate: true,
    },
    sendiri(val) {
      if (val === true) {
        this.selectedPic = null;
        this.form.selected_pic = this.username;
      }
      if (
        this.karyawanLoading ||
        this.bagianLoading ||
        this.detailLoading ||
        this.rombonganLoading ||
        this.lokasiLoading
      )
        return;
      this.rombonganOrganiks = [
        { loading: false, selected: null, entries: [], search: "" }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
      ];

      if (this.$store.state.editSppd === true) this.getKaryawanRombongan();
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => {
        this[l] = false;
        this.loader = null;
        this.createGeneralMemo = false;
      }, 3000);
    },
    tipToHide(val) {
      if (!val) return;
      createHelper.hideHint(val);
    },
  },
  methods: {
    ...mapActions("resources", ["resetRecipientPeerUpline", "getDataResource"]),
    ...mapActions(["setMessageBox"]),
    ...mapActions("draft", [
      "setMemoToReply",
      "setAxiosLoading",
      "setDraftIsSaved",
      "loadDraft",
      "deleteServerAttachment",
    ]),
    changeEditorData(e) {
      this.form.isi = e;
    },
    cleanupIntroJs() {
      // Hapus elemen-elemen sisa yang terkait dengan introJs
      const tooltips = document.querySelectorAll(
        ".introjs-tooltipReferenceLayer, .introjs-helperLayer, .introjs-fixParent"
      );
      tooltips.forEach((el) => el.remove());

      // Hapus tooltip dari DOM
      const tooltipElements = document.querySelectorAll(".introjs-tooltip");
      tooltipElements.forEach((el) => el.remove());
    },
    itemsRombongan(entries) {
      if (!entries.length) return null;
      if (entries.length < 1) return null;
      return entries.map((entry) => {
        const Description = entry.nama;
        return Object.assign({}, entry, { Description });
      });
    },
    async getDaftarKaryawan() {
      if (this.karyawanLoading) return;
      this.karyawanLoading = true;
      try {
        const response = await http.get(
          `src/list-karyawan?searchkey=${this.qSearchKaryawan}&selected_nik=${this.selectedOrgaNik}`
        );
        if (response && response.status == "success") {
          this.entriesPic = response.data;
        }
      } catch (error) {
        console.error(error);
        this.karyawanLoading = false;
      } finally {
        this.karyawanLoading = false;
      }
    },
    async getOriginalSppd(query) {
      // Hanya lakukan pencarian jika query lebih dari atau sama dengan 3 karakter
      if (query.length < 3) {
        this.listSppdInduk = []; // Kosongkan hasil jika kurang dari 3 karakter
        this.oriIsLoading = false;
        return;
      }
      // Set loading state
      this.oriIsLoading = true;
      try {
        const response = await http.get(
          `src/list-sppd-induk?searchkey=${query}`
        );
        if (response) {
          // Map data dari API ke format yang diinginkan
          this.listSppdInduk = response.data;
        }
      } catch (error) {
        console.error("Error fetching items:", error);
      } finally {
        this.oriIsLoading = false; // Selesai loading
      }
    },
    async getDaftarLokasi() {
      if (this.lokasiLoading) return;
      if (!this.qSearchLokasi) return;
      if (this.qSearchLokasi.length < 3) return;

      this.lokasiLoading = true;
      try {
        const response = await http.get(
          `src/list-kota-provinsi?searchkey=${this.qSearchLokasi}`
        );
        if (response && response.status == "success") {
          this.entriesLokasi = response.data;
        }
      } catch (error) {
        console.error(error);
        this.lokasiLoading = false;
      } finally {
        this.lokasiLoading = false;
      }
    },
    async getAllKaryawanDivisi() {
      if (this.karyawanLoading === true) return;
      this.karyawanLoading = true;
      try {
        const response = await http.get(
          `src/list-karyawan?must_filter=0&searchkey=&selected_pic=${
            this.selectedPic ? this.selectedPic.nik_sap : this.username
          }&selected_nik=${this.selectedOrgaNik}`
        );
        if (response && response.status == "success") {
          this.karyawanDivisi = response.data;
          // this.entries = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.karyawanLoading = false;
      }
    },
    async getKaryawanRombongan() {
      if (this.rombonganLoading === true) return;
      this.rombonganLoading = true;
      try {
        const response = await http.get(
          `src/list-karyawan?must_filter=1&searchkey=&selected_pic=${
            this.selectedPic ? this.selectedPic.nik_sap : this.username
          }&selected_nik=${this.selectedOrgaNik}`
        );
        if (response && response.status == "success") {
          this.karyawanRombongan = response.data;
          // this.entries = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.rombonganLoading = false;
      }
    },
    async getAllBagian() {
      if (this.bagianLoading === true) return;
      this.bagianLoading = true;
      try {
        const response = await http.get(
          `src/list-bagian?searchkey=&selected_bagian=`
        );
        if (response) {
          this.listBagian = response;
          // this.entries = response.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.bagianLoading = false;
      }
    },
    async getSppdDetail() {
      if (this.detailLoading === true) return;
      let idHash = this.$store.state.editSppd.id;
      let mode = "draft";
      if (this.$route.name === "revision.detail") {
        idHash = this.$route.params.id;
        mode = "revision";
      }
      this.detailLoading = true;
      try {
        const response = await http.get(
          `memo/myrevision/${idHash}/detail-sppd?mode=${mode}`
        );
        if (response && response.data) {
          const memo = response.data.memo;
          this.form.jenis_perjalanan = memo.sppd_jenis_perjalanan;
          this.perpanjangan =
            parseInt(memo.sppd_perpanjangan) === 1 ? true : false;
          this.form.tujuan_dinas_detail = memo.sppd_tujuan_dinas_detail;
          this.form.nama_provinsi = memo.sppd_tujuan_dinas;
          this.form.keperluan_dinas = memo.sppd_keperluan_dinas;
          this.form.transport = memo.sppd_transport;
          this.form.transport_lokasi_dinas = memo.sppd_transport_lokasi_dinas;
          this.form.tanggal_kembali = memo.sppd_tanggal_kembali;
          this.form.tanggal_berangkat = memo.sppd_tanggal_berangkat;
          // this.form.attachment = response.data.attachment;
          this.form.nomor_sppd_induk = memo.nomor_induk;
          this.form.id_sppd_induk = memo.sppd_induk_id;
          if (memo.original_sppd) {
            this.form.original_sppd = memo.original_sppd;
            this.listSppdInduk.push(memo.original_sppd);
          }
          this.qSearchOriginal = memo.original_sppd
            ? memo.original_sppd.search_detail
            : "";

          this.serverAttachment = response.data.attachment;
          this.form.bagian_inisiator = {
            id: memo.sppd_bagian_inisiator_id,
            inisial_bagian: memo.sppd_bagian_inisiator,
          };

          if (memo.sppd_nik_pic !== this.username) {
            this.sendiri = false;
            this.selectedPic = memo.sppd_detail_pic;
            this.form.selected_pic = memo.sppd_nik_pic;
          } else {
            this.sendiri = true;
          }

          this.getKaryawanRombongan();

          if (response.data.pesertaNonPtpn4)
            this.parsePesertaNonPtpn4(response.data.pesertaNonPtpn4);
          if (response.data.pesertaPtpn4)
            this.parsePesertaPtpn4(response.data.pesertaPtpn4);
          this.parseKotaProvinsi(memo.detail_tujuan_kota);
        }
      } catch (error) {
        if (error.response) console.error(error.response);
      } finally {
        this.detailLoading = false;
      }
    },
    parsePesertaNonPtpn4(data) {
      if (data.length >= 1) {
        this.rombonganNonOrganiks = [];
        data.forEach((item) => {
          this.rombonganNonOrganiks.push(item);
        });
        this.form.rombongan_non_organik = "true";
      }
    },
    parsePesertaPtpn4(data) {
      if (data.length >= 1) {
        this.rombonganOrganiks = [];
        data.forEach((item) => {
          this.rombonganOrganiks.push({
            loading: false,
            selected: item,
            entries: [],
            search: "",
          });
        });
        this.form.rombongan_organik = "true";
      }
    },
    async parseKotaProvinsi(selectedKota) {
      //ambil daftar kota berdasarkan nama kota yang dipilih
      if (!selectedKota || selectedKota === "Luar Negeri") return;
      this.qSearchLokasi = selectedKota.nama_kota;
      await this.getDaftarLokasi();
      this.$nextTick(() => {
        this.selectedLokasi = selectedKota;
        this.canChangeSppdInduk = true;
      }, 500);
    },
    addRombongan(tipe) {
      if (tipe === "non_organik") {
        this.rombonganNonOrganiks.push({
          nama: null,
          grade: null,
          status_karyawan: null,
        }); // Tambah objek baru
      } else {
        this.rombonganOrganiks.push({
          loading: false,
          selected: null,
          entries: [],
          search: "",
        }); // Tambah objek baru
      }
    },
    removeRombongan(tipe, index) {
      if (tipe === "non_organik") {
        if (this.rombonganNonOrganiks.length > 1) {
          this.rombonganNonOrganiks.splice(index, 1); // Hapus objek berdasarkan indeks
        } else {
          this.rombonganNonOrganiks = [
            { nama: null, grade: null, status_karyawan: null }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
          ];
          this.form.rombongan_non_organik = false;
        }
      } else {
        if (this.rombonganOrganiks.length > 1) {
          this.rombonganOrganiks.splice(index, 1); // Hapus objek berdasarkan indeks
        } else {
          this.rombonganOrganiks = [
            { loading: false, selected: null, entries: [], search: "" }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
          ];
          this.form.rombongan_organik = false;
        }
      }
    },

    formReset() {
      this.canChangeSppdInduk = false;
      this.setDraftIsSaved(false);
      this.$v.$reset();
      this.axiosProcessing = false;
      this.qSearchKaryawan = "";
      this.qSearchLokasi = null;
      this.lastQRombongan = "";
      this.daftarKaryawan = [];
      this.selectedPic = null;
      this.menuTglBerangkat = false;
      this.menuTglKembali = false;
      this.descriptionLimit = 60;
      this.entriesPic = [];
      this.entriesLokasi = [];
      this.selectedLokasi = null;
      this.karyawanDivisi = [];
      this.rombonganOrganiks = [
        { loading: false, selected: null, entries: [], search: "" }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
      ];
      this.rombonganNonOrganiks = [
        { nama: null, grade: null, status_karyawan: null }, // Inisialisasi dengan objek yang diperlukan untuk v-autocomplete
      ];
      this.sendiri = true;
      this.perpanjangan = false;
      this.qSearchOriginal = "";
      this.listSppdInduk = [];
      this.oriIsLoading = false;
      this.form = {
        jenis_perjalanan: "",
        original_sppd: null,
        tujuan_dinas_detail: null,
        rombongan_organik: false,
        rombongan_non_organik: false,
        selected_pic: null,
        nama_provinsi: null,
        keperluan_dinas: "",
        transport: null,
        transport_lokasi_dinas: null,
        tanggal_kembali: null,
        tanggal_kembali_induk: null,
        tanggal_berangkat: null,
        attachment: null,
        bagian_inisiator: null,
        id_sppd_induk: null,
        nomor_sppd_induk: null,
      };
    },
    showHideUplineDialog(val) {
      this.showUplineDialog = val;
    },
    jenisPerjalananFilter(item, queryText) {
      const textOne = item.nama.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    menuAction(command) {
      this.lastAction = command;
      if (!command) return;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      switch (command) {
        case "save_and_close":
          this.submitSppd("save");
          break;
        case "submit":
          this.submitSppd("submit");
          break;
        case "help":
          this.showHelper();
          break;
        default:
          break;
      }
    },
    async submitSppd(mode) {
      if (this.axiosProcessing === true) return;
      if (
        this.nikIsDuplicate ||
        this.memberIsDuplicate ||
        this.memberIsUser ||
        !this.dateIsValid ||
        !this.canRentACar
      )
        return;

      if (
        this.tipeKrani.includes(this.currentUser.level_aplikasi_elemen) &&
        !this.form.selectedUpline &&
        mode !== "save"
      ) {
        return (this.showUplineDialog = true);
        //open upline selector if krani
      }

      if (this.form.rombongan_organik) {
        if (!this.selectedOrgaNik || this.selectedOrgaNik.length < 1) {
          return this.$dialog.error({
            text: this.$t(
              "msgbox.you checked the organic group but did not fill in the participants"
            ),
            title: this.$t("msgbox.error"),
            persistent: true,
            actions: [
              {
                text: this.$t("button.ok"),
                color: "success",
                key: false,
                icon: mdiCheck,
              },
            ],
          });
        }
      }
      if (this.form.rombongan_non_organik) {
        if (!this.selectedNonOrgaNik || this.selectedNonOrgaNik.length < 1) {
          return this.$dialog.error({
            text: this.$t(
              "msgbox.you checked the non organic group but did not fill in the participants"
            ),
            title: this.$t("msgbox.error"),
            persistent: true,
            actions: [
              {
                text: this.$t("button.ok"),
                color: "success",
                key: false,
                icon: mdiCheck,
              },
            ],
          });
        }
        if (!this.checkForNullValues(this.rombonganNonOrganiks)) {
          return this.$dialog.error({
            text: this.$t(
              "msgbox.please complete all non-organic participant data items"
            ),
            title: this.$t("msgbox.error"),
            persistent: true,
            actions: [
              {
                text: this.$t("button.ok"),
                color: "success",
                key: false,
                icon: mdiCheck,
              },
            ],
          });
        }
      }

      this.axiosProcessing = true;
      let formData = new FormData();
      formData.append("_method", "PUT");
      if (this.perpanjangan === true) {
        formData.append("perpanjangan", 1);
        formData.append(
          "sppd_induk",
          this.form.original_sppd ? this.form.original_sppd.id : ""
        );
      }
      formData.delete("selectedUpline");
      if (this.form.selectedUpline)
        formData.append("initiator_nik", this.form.selectedUpline);

      formData.append("jenis_perjalanan", this.form.jenis_perjalanan); //luar wilayah/dalam wilayah/luar negeri
      if (this.form.jenis_perjalanan !== "luar_negeri") {
        formData.append("tujuan_kota_id", this.selectedLokasi.cid);
        formData.append("tujuan_nama_kota", this.form.nama_kota);
      }
      if (this.form.selected_pic) {
        formData.append("nik_pic", this.form.selected_pic); //jika bukan username sebagai PIC
      } else {
        formData.append("nik_pic", this.username); //username sebagai default PIC
      }
      formData.append("tujuan_dinas_detail", this.form.tujuan_dinas_detail);
      formData.append("keperluan_dinas", this.form.keperluan_dinas);
      formData.append("transport", this.form.transport);
      formData.append("tanggal_berangkat", this.form.tanggal_berangkat);
      formData.append("tanggal_kembali", this.form.tanggal_kembali);
      formData.append(
        "transport_lokasi_dinas",
        this.form.transport_lokasi_dinas
      );
      formData.append(
        "bagian_inisiator",
        this.form.bagian_inisiator.inisial_bagian
      );
      formData.append("bagian_inisiator_id", this.form.bagian_inisiator.id);
      formData.append("action", mode);

      if (this.selectedOrgaNik)
        formData.append("rombongan_organik", this.selectedOrgaNik);
      if (this.selectedNonOrgaNik) {
        this.rombonganNonOrganiks.forEach((item, index) => {
          formData.append(`rombongan_non_organik[${index}][nama]`, item.nama);
          formData.append(`rombongan_non_organik[${index}][grade]`, item.grade);
          formData.append(
            `rombongan_non_organik[${index}][status_karyawan]`,
            item.status_karyawan
          );
        });
      }
      if (this.form.attachment)
        formData.append("attachment", this.form.attachment);
      //getCurrentIdhashForUpdate
      let currHashid = "";
      if (this.$route.name === "revision.detail") {
        currHashid = this.$route.params.id;
      } else {
        currHashid = this.editSppd.id;
      }
      if (currHashid === "") {
        return this.$dialog.error({
          text: this.$t(
            "msgbox.failed to submit sppd draft due to empty record id"
          ),
          title: this.$t("msgbox.submit-failed"),
          persistent: true,
          actions: [
            {
              text: this.$t("button.ok"),
              color: "success",
              key: false,
              icon: mdiCheck,
            },
          ],
        });
      }

      try {
        const response = await http.post(`sppd/${currHashid}`, formData);
        if (response && response.status == "success") {
          if (mode === "save") {
            this.$dialog.info({
              text: this.$t("msgbox.save sppd revision is success"),
              title: this.$t("msgbox.save success"),
              persistent: true,
              actions: [
                {
                  text: this.$t("button.ok"),
                  color: "success",
                  key: false,
                  icon: mdiCheck,
                },
              ],
            });
          } else {
            this.$dialog.info({
              text: this.$t("msgbox.submit-draft-to-upline-success"),
              title: this.$t("msgbox.submit-success"),
              persistent: true,
              actions: [
                {
                  text: this.$t("button.ok"),
                  color: "success",
                  key: false,
                  icon: mdiCheck,
                },
              ],
            });
          }
          this.formReset();
          this.editSppd.status = false;
          this.loadDraft(this.filterParams);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.axiosProcessing = false;
        this.getAllKaryawanDivisi();
      }
    },

    showHelper() {
      setTimeout(() => {
        createHelper.setOptions({
          hintButtonLabel: this.$t("button.i-understand"),
        });
        createHelper.addHints();
        // createHelper.refresh();
        createHelper.showHints();
      }, 500);
    },
    uplineSelected(val) {
      if (!val) return;
      this.form.selectedUpline = val.nik;
      this.menuAction("submit");
    },
    froalaIsWorking(val) {
      this.editorIsWorking = val;
    },
    confirmDeleteAttachment() {
      if (!this.serverAttachment) return;

      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-delete-this-attachment"),
          title: this.$t("msgbox.delete-attachment"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "primary", key: false },
            { text: this.$t("button.yes-delete"), color: "red", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;

          let formData = {
            memoId: this.serverAttachment.memo_id_hash,
            id_hash: this.serverAttachment.id_hash,
            _method: "DELETE",
          };
          this.deleteServerAttachment(formData).then((result) => {
            if (result.data == true) {
              this.serverAttachment = null;
            }
          });
        });
    },
    async getSppdInduk(id) {
      //autofill form
      if (this.detailLoading === true) return;
      this.detailLoading = true;
      this.updateOverlay(true);
      try {
        const response = await http.get(
          `memo/myrevision/${id}/detail-sppd?mode=draft`
        );
        if (response && response.data) {
          const memo = response.data.memo;
          this.form.jenis_perjalanan = memo.sppd_jenis_perjalanan;
          this.form.tujuan_dinas_detail = memo.sppd_tujuan_dinas_detail;
          this.form.nama_provinsi = memo.sppd_tujuan_dinas;
          this.form.keperluan_dinas = memo.sppd_keperluan_dinas;
          this.form.transport = memo.sppd_transport;
          this.form.transport_lokasi_dinas = memo.sppd_transport_lokasi_dinas;
          this.form.tanggal_kembali = memo.sppd_tanggal_kembali;
          this.form.tanggal_kembali_induk = memo.sppd_tanggal_kembali;
          this.form.tanggal_berangkat = memo.sppd_tanggal_kembali;
          this.form.nomor_sppd_induk = memo.nomor;
          this.form.id_sppd_induk = memo.id;
          // this.form.attachment = response.data.attachment;
          this.serverAttachment = response.data.attachment;
          this.form.bagian_inisiator = {
            id: memo.sppd_bagian_inisiator_id,
            inisial_bagian: memo.sppd_bagian_inisiator,
          };

          if (memo.sppd_nik_pic !== this.username) {
            this.sendiri = false;
            this.selectedPic = memo.sppd_detail_pic;
            this.form.selected_pic = memo.sppd_nik_pic;
          } else {
            this.sendiri = true;
          }

          this.getKaryawanRombongan();

          if (response.data.pesertaNonPtpn4)
            this.parsePesertaNonPtpn4(response.data.pesertaNonPtpn4);
          if (response.data.pesertaPtpn4)
            this.parsePesertaPtpn4(response.data.pesertaPtpn4);
          this.parseKotaProvinsi(memo.detail_tujuan_kota);
        }
      } catch (error) {
        if (error.response) console.error(error.response);
      } finally {
        this.updateOverlay(false);
        this.detailLoading = false;
      }
    },
    downloadAttachment(mode, params) {
      if (!params) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };

      this.updateOverlay(true);

      http
        .get(`/memo/${params.memo_id}/attachments/${params.id_hash}`, options)
        .then((response) => {
          if (response.status === 500) return this.updateOverlay(false);
          let arrImageType = ["image/jpeg", "image/png"];
          if (response) {
            if (mode === "download") {
              let dfileURL = window.URL.createObjectURL(new Blob([response]));
              let dfileLink = document.createElement("a");

              dfileLink.href = dfileURL;
              dfileLink.setAttribute("download", params.file_name);
              document.body.appendChild(dfileLink);

              this.updateOverlay(false);
              dfileLink.click();
            } else {
              if (arrImageType.includes(params.file_type)) {
                let file = new File([response], "Download.png", {
                  type: "image/png",
                });
                var fileURL = URL.createObjectURL(file);
                let newWindow = window.open();
                newWindow.document.write(
                  `<img src="${fileURL}" alt="Downloaded Image"/>`
                );
              } else if (params.file_type === "application/pdf") {
                var file = new Blob([response], {
                  type: "application/pdf;base64",
                });
                fileURL = URL.createObjectURL(file);
                window.open(fileURL);
              }
            }
            this.updateOverlay(false);
          }
        });
    },
    updateOverlay(val) {
      this.overlay = val;
    },
    async downloadPdfInduk(mode, id) {
      if (!IDBDatabase) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };
      this.updateOverlay(true);
      http.get(`/memo/myrevision/${id}/getpdf`, options).then((response) => {
        if (response.status === 500) return this.updateOverlay(false);

        if (response) {
          if (mode === "download") {
            let dfileURL = window.URL.createObjectURL(new Blob([response]));
            let dfileLink = document.createElement("a");

            let namaFile = this.form.nomor_sppd_induk.replace("/", "_");

            dfileLink.href = dfileURL;
            dfileLink.setAttribute("download", namaFile + ".pdf");
            document.body.appendChild(dfileLink);

            this.updateOverlay(false);
            dfileLink.click();
          } else {
            var file = new Blob([response], {
              type: "application/pdf;base64",
            });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
          this.updateOverlay(false);
        }
      });
    },
  },
  filters: {
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
};
</script>
<template>
  <div>
    <v-dialog
      content-class="create-general-memo-modal"
      v-model="editSppd.status"
      persistent
      scrollable
      max-width="960"
      origin="center center"
      overlay-opacity=".3"
    >
      <v-card id="editSppd" class="height-95vh" v-if="detailLoading">
        <v-card-text
          height="100%"
          style="
            display: flex;
            margin: 0 auto;
            padding: 5rem;
            width: 100%;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
          "
        >
          <p style="font-size: 1.2rem">LOADING...</p>
        </v-card-text>
      </v-card>
      <v-card id="editSppd" class="height-95vh" v-else>
        <v-card-title class="pa-0">
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t("card.edit ppd") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind:data-hint="$t('introjs.this-button-for-submit-action')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-bind:loading="axiosProcessing"
                  v-bind:disabled="axiosProcessing"
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="menuAction('submit')"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon dark class="mr-2">
                    {{ iconSubmit }}
                  </v-icon>
                  {{ $t("button.submit") }}
                </v-btn>
              </template>
              <span>{{ $t("tooltip.submit-to-upline") }}</span>
            </v-tooltip>

            <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind:data-hint="$t('introjs.this-button-for-save-as-draft')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-bind:loading="axiosProcessing"
                  v-bind:disabled="axiosProcessing"
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="menuAction('save_and_close')"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon dark class="mr-2">
                    {{ iconSave }}
                  </v-icon>
                  {{ $t("button.save") }}
                </v-btn>
              </template>
              <span>{{ $t("tooltip.save-as-draft") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="showHelper" v-on="on" v-bind="attrs">
                  <v-icon>{{ iconHelp }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.help-me") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="editSppd = { id: null, status: false }"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon>{{ iconClose }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.close-not-save") }}</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text height="100%">
          <v-row class="mt-3">
            <v-col cols="12" sm="12" lg="4" class="pt-0 pb-xs-0 mb-3">
              <label
                for="chkExtendedSppd"
                style="padding-left: 0.3rem; font-size: 0.87rem"
                >Apakah ini adalah SPPD Perpanjangan?</label
              >
              <v-checkbox
                v-model="perpanjangan"
                :label="$t('label.extended sppd')"
                style="margin-top: 0em"
                color="primary"
                id="chkExtendedSppd"
                :value="true"
                hide-details
                aria-checked="true"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" lg="8" class="pt-0 pb-xs-0 mb-3">
              <v-autocomplete
                :data-hint="$t('introjs.this is original sppd selector')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
                clearable
                return-object
                v-bind:search-input.sync="qSearchOriginal"
                v-bind:menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                :disabled="!perpanjangan"
                id="sppdEditIndukSelector"
                v-model="form.original_sppd"
                :items="listSppdInduk"
                :loading="oriIsLoading"
                hide-no-data
                hide-selected
                auto-select-first
                hide-details="true"
                item-text="search_detail"
                item-value="id"
                :prepend-icon="iconMenu"
                :label="$t('label.original sppd')"
                :placeholder="$t('label.original sppd')"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.search_detail }}
                </template>
              </v-autocomplete>
              <div
                v-if="form.nomor_sppd_induk"
                style="margin-top: 1rem; margin-left: 2.2rem"
              >
                <span style="display: block; font-size: 0.87em">
                  {{ $t("label.original sppd pdf") }}
                </span>
                <v-icon style="transform: rotate(90deg)">{{
                  iconAttachment
                }}</v-icon>
                <span style="margin-left: 0.5rem"
                  >{{ form.nomor_sppd_induk ?? "gagal_memuat_file_pdf" }}
                </span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="btn-sppd-download-attachment"
                      icon
                      @click="downloadPdfInduk('view', form.id_sppd_induk)"
                      v-on="on"
                      color="blue"
                      v-bind="attrs"
                    >
                      <v-icon>{{ iconEye }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.show original pdf") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="btn-sppd-download-attachment"
                      icon
                      @click="downloadPdfInduk('download', form.id_sppd_induk)"
                      v-on="on"
                      color="blue"
                      v-bind="attrs"
                    >
                      <v-icon>{{ iconDownload }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.download original pdf") }}</span>
                </v-tooltip>
              </div>
              <div
                class="error--badge-container pulse--animation"
                v-if="
                  $v.form.bagian_inisiator.$error &&
                  !$v.form.bagian_inisiator.required
                "
              >
                <span class="error--badge">
                  {{ $t("error.initiator department must be selected") }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" sm="12" lg="4" class="pt-0 pb-xs-0 mb-3">
              <v-autocomplete
                :data-hint="$t('introjs.this is jenis perjalanan selector')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
                v-model="form.jenis_perjalanan"
                v-bind:items="jenisPerjalanan"
                v-bind:menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                auto-select-first
                hide-details="true"
                item-text="title"
                item-value="value"
                clearable
                :prepend-icon="iconMenu"
                label="Jenis Perjalanan"
                placeholder="Jenis Perjalanan"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.title }}
                </template>
              </v-autocomplete>
              <div
                class="error--badge-container pulse--animation"
                v-if="
                  $v.form.jenis_perjalanan.$error &&
                  !$v.form.jenis_perjalanan.required
                "
              >
                <span class="error--badge">
                  {{ $t("error.the type of travel must be selected") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="12" lg="8" class="pt-0 pb-xs-0 mb-3">
              <v-autocomplete
                :data-hint="$t('introjs.this is initiator selector')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
                clearable
                return-object
                v-bind:menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                id="inisiatorInput"
                v-model="form.bagian_inisiator"
                :items="listBagian"
                hide-no-data
                hide-selected
                auto-select-first
                hide-details="true"
                item-text="nama_bagian"
                item-value="inisial_bagian"
                :prepend-icon="iconMenu"
                :label="$t('label.initiator department')"
                :placeholder="$t('label.initiator department')"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.nama_bagian }}
                </template>
              </v-autocomplete>
              <div
                class="error--badge-container pulse--animation"
                v-if="
                  $v.form.bagian_inisiator.$error &&
                  !$v.form.bagian_inisiator.required
                "
              >
                <span class="error--badge">
                  {{ $t("error.initiator department must be selected") }}
                </span>
              </div>
            </v-col>
          </v-row>

          <!-- Tujuan Dinas -->

          <v-row class="mt-3">
            <v-col cols="12" md="5" class="pt-0 pb-xs-0">
              <v-text-field
                :prepend-icon="iconEdit"
                v-model="form.tujuan_dinas_detail"
                :label="$t('label.official purpose')"
                hide-details="auto"
              ></v-text-field>
              <div
                v-if="$v.form.tujuan_dinas_detail.$error"
                class="error--badge-container -margin-top pulse--animation"
                style="margin-top: 0.1rem"
              >
                <span
                  v-if="!$v.form.tujuan_dinas_detail.required"
                  class="error--badge"
                >
                  {{ $t("error.official purpose must be filled") }}
                </span>
                <span
                  v-if="!$v.form.tujuan_dinas_detail.maxLength"
                  class="error--badge"
                >
                  {{ $t("error.max length is 100") }}
                </span>
                <span
                  v-if="!$v.form.tujuan_dinas_detail.minLength"
                  class="error--badge"
                >
                  {{ $t("error.min length is 10") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 pb-xs-0">
              <v-autocomplete
                v-if="form.jenis_perjalanan !== 'luar_negeri'"
                :data-hint="$t('introjs.this is destination city selector')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
                :prepend-icon="iconMapSearch"
                v-model="selectedLokasi"
                :items="itemsLokasi"
                :loading="lokasiLoading"
                :search-input.sync="qSearchLokasi"
                hide-no-data
                hide-selected
                item-text="Description"
                item-value="API"
                label="Kota Provinsi"
                placeholder="Ketik Nama Kota"
                clearable
                return-object
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.nama_kota }}
                </template>
              </v-autocomplete>
              <div
                v-if="
                  $v.form.nama_provinsi.$error &&
                  !$v.form.nama_provinsi.required
                "
                class="error--badge-container -margin-top pulse--animation"
              >
                <span class="error--badge">
                  {{
                    $t(
                      "error.you have to choose the name of the provincial city"
                    )
                  }}
                </span>
              </div>
            </v-col>
            <v-col
              v-if="selectedLokasi"
              cols="12"
              md="3"
              class="auto--pl pt-0 pb-xs-0"
            >
              <p style="display: block">
                <span
                  style="font-size: 11px; display: block; margin-bottom: 0.2rem"
                  >Nama Provinsi</span
                >
                {{ selectedLokasi?.nama_provinsi }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0 pb-xs-0">
              <v-textarea
                :prepend-icon="iconEdit"
                clearable
                auto-grow
                rows="2"
                label="Keperluan Dinas"
                v-model="form.keperluan_dinas"
              ></v-textarea>
              <div class="error--badge-container -margin-top pulse--animation">
                <span
                  class="error--badge"
                  v-if="
                    $v.form.keperluan_dinas.$error &&
                    !$v.form.keperluan_dinas.required
                  "
                >
                  {{ $t("error.fill in official requirements") }}
                </span>
                <span
                  class="error--badge"
                  v-if="
                    $v.form.keperluan_dinas.$error &&
                    !$v.form.keperluan_dinas.minLength
                  "
                >
                  {{ $t("error.min length is 10 chars") }}
                </span>
                <span
                  class="error--badge"
                  v-if="
                    $v.form.keperluan_dinas.$error &&
                    !$v.form.keperluan_dinas.maxLength
                  "
                >
                  {{ $t("error.max length is 200 chars") }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="3" class="pt-0 pb-xs-0">
              <v-menu
                v-model="menuTglBerangkat"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.tanggal_berangkat"
                    :label="$t('label.departure date')"
                    :prepend-icon="iconTgl"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.tanggal_berangkat"
                  @input="menuTglBerangkat = false"
                ></v-date-picker>
              </v-menu>
              <div
                v-if="
                  $v.form.tanggal_berangkat.$error &&
                  !$v.form.tanggal_berangkat.required
                "
                class="error--badge-container -margin-top pulse--animation mt-1"
              >
                <span class="error--badge" v-if="true">
                  {{ $t("error.select departure date") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" lg="3" class="pt-0 pb-xs-0">
              <v-menu
                v-model="menuTglKembali"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.tanggal_kembali"
                    :label="$t('label.arrival date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.tanggal_kembali"
                  @input="menuTglKembali = false"
                ></v-date-picker>
              </v-menu>
              <div
                v-if="
                  $v.form.tanggal_kembali.$error &&
                  !$v.form.tanggal_kembali.required
                "
                class="error--badge-container -margin-top pulse--animation mt-1"
              >
                <span class="error--badge" v-if="true">
                  {{ $t("error.select departure date") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" lg="6" class="pt-0 pb-xs-0 auto--pl">
              <v-combobox
                :prepend-icon="iconPlaneCar"
                clearable
                hide-selected
                :label="$t('label.transportation')"
                v-model="form.transport"
                :items="transportList"
                :data-hint="$t('introjs.this is transport selector')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
              ></v-combobox>
              <div
                class="error--badge-container -margin-top pulse--animation mt-1"
                style="padding-left: 0rem"
                v-if="$v.form.transport.$error && !$v.form.transport.required"
              >
                <span class="error--badge">
                  {{ $t("error.select transportation") }}
                </span>
              </div>
            </v-col>
          </v-row>
          <div
            v-if="!dateIsValid"
            class="error--badge-container -margin-top pulse--animation mb-2"
            style="width: 50%"
          >
            <span class="error--badge" v-if="true">
              {{
                $t("error.the return date must be set after the departure date")
              }}
            </span>
          </div>
          <v-row>
            <v-col cols="12" md="6" class="pt-0 pb-xs-0">
              <v-autocomplete
                :data-hint="$t('introjs.this is on site transport selector')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
                v-model="form.transport_lokasi_dinas"
                v-bind:items="transportDiTujuan"
                v-bind:menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                auto-select-first
                hide-details="true"
                item-text="title"
                item-value="value"
                clearable
                :prepend-icon="iconCar"
                label="Kendaraan Di Lokasi Dinas"
                placeholder="Kendaraan Di Lokasi Dinas"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.title }}
                </template>
              </v-autocomplete>
              <div
                v-if="
                  $v.form.transport_lokasi_dinas.$error &&
                  !$v.form.transport_lokasi_dinas.required
                "
                class="block error--badge-container -margin-top pulse--animation mb-2 mt-2"
              >
                <span class="error--badge mb-1">
                  {{ $t("error.select on site transport") }}
                </span>
              </div>
              <div
                v-if="!canRentACar"
                class="error--badge-container -margin-top pulse--animation mb-2 mt-2"
              >
                <span class="error--badge mb-1">
                  {{ $t("error.the minimum group departing is two pax") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" lg="6" class="pt-0 pb-xs-0">
              <v-file-input
                v-if="!serverAttachment"
                accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation
                      , application/vnd.rar, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword,
                      application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
                v-model="form.attachment"
                :label="$t('label.supporting file')"
                show-size
                truncate-length="15"
              ></v-file-input>
              <div v-else>
                <span style="display: block; font-size: 0.87em">
                  {{ $t("label.attachment") }}
                </span>
                <v-icon style="transform: rotate(90deg)">{{
                  iconAttachment
                }}</v-icon>
                <span style="margin-left: 0.5rem"
                  >{{ this.serverAttachment?.file_name }} ({{
                    this.serverAttachment?.file_size | formatSize
                  }})</span
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="btn-sppd-attachment-delete"
                      icon
                      @click="confirmDeleteAttachment()"
                      v-on="on"
                      color="red"
                      v-bind="attrs"
                    >
                      <v-icon>{{ iconDelete }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.remove attachment") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="btn-sppd-download-attachment"
                      icon
                      @click="
                        downloadAttachment('view', {
                          memo_id: serverAttachment.memo_id_hash,
                          id_hash: serverAttachment.id_hash,
                          file_name: serverAttachment.file_name,
                          file_type: serverAttachment.file_type,
                        })
                      "
                      v-on="on"
                      color="blue"
                      v-bind="attrs"
                    >
                      <v-icon>{{ iconEye }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.show attachment") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="btn-sppd-download-attachment"
                      icon
                      @click="
                        downloadAttachment('download', {
                          memo_id: serverAttachment.memo_id_hash,
                          id_hash: serverAttachment.id_hash,
                          file_name: serverAttachment.file_name,
                          file_type: serverAttachment.file_type,
                        })
                      "
                      v-on="on"
                      color="blue"
                      v-bind="attrs"
                    >
                      <v-icon>{{ iconDownload }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.remove attachment") }}</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
          <v-spacer class="border-b"></v-spacer>
          <v-row>
            <v-col cols="12" sm="12" lg="3" class="pt-0 pb-xs-0 mt-2">
              <label
                for="chkCreateSendiri"
                style="padding-left: 0.3rem; font-size: 0.87rem"
                >Nama Karyawan PIC</label
              >
              <v-checkbox
                :model-value="sendiri"
                v-model="sendiri"
                :label="$t('label.my self')"
                style="margin-top: 0em"
                color="primary"
                id="chkCreateSendiri"
                hide-details
                aria-checked="true"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mt-3" v-if="!sendiri">
            <v-col cols="12" sm="12" md="6" class="pt-0 pb-xs-0">
              <v-autocomplete
                :data-hint="$t('introjs.this is pic selector')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
                v-bind:search-input.sync="qSearchKaryawan"
                @change="qSearchKaryawan = ''"
                v-model="selectedPic"
                :items="karyawanDivisi"
                hide-no-data
                hide-selected
                item-text="nama"
                item-value="nik_sap"
                label="Nama Karyawan (Kepala Rombongan)"
                placeholder="Ketik NIK, Nama atau Jabatan"
                :prepend-icon="iconSearch"
                clearable
                return-object
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.nama }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="selectedPic"
              cols="12"
              sm="6"
              md="2"
              class="auto--pl pt-0 pb-xs-0"
            >
              <p style="display: block">
                <span
                  style="font-size: 11px; display: block; margin-bottom: 0.2rem"
                  >NIK SAP</span
                >
                {{ selectedPic.nik_sap }}
              </p>
            </v-col>
            <v-col
              v-if="selectedPic"
              cols="12"
              sm="6"
              md="4"
              class="auto--pl pt-0 pb-xs-0"
            >
              <p style="display: block">
                <span
                  style="font-size: 11px; display: block; margin-bottom: 0.2rem"
                  >Jabatan/Bagian</span
                >
                {{ selectedPic?.nama_jabatan }}
                <br />
                {{ selectedPic?.nama_bagian }}
                <br />
                <strong>({{ selectedPic?.grade_sppd }})</strong>
              </p>
            </v-col>
          </v-row>
          <v-row style="margin-top: 0.1rem">
            <v-col cols="12">
              <div class="error--badge-container -margin-top pulse--animation">
                <span
                  class="error--badge"
                  v-if="
                    $v.form.selected_pic.$error &&
                    !$v.form.selected_pic.required
                  "
                >
                  {{ $t("error.you must select one employee") }}
                </span>
                <span class="error--badge" v-if="nikIsDuplicate">
                  {{ $t("error.nik is duplicate") }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="pt-0 pb-xs-0">
              <v-checkbox
                :model-value="form.rombongan_organik"
                v-model="form.rombongan_organik"
                label="Rombongan Karyawan PTPN IV"
                color="primary"
                hide-details
                aria-checked="false"
                v-bind:data-hint="
                  $t('introjs.this checkbox for toggle ptpn4 member')
                "
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
              ></v-checkbox>
            </v-col>
          </v-row>
          <div class="error--badge-container -margin-top pulse--animation mt-1">
            <span class="error--badge mb-1" v-if="nikIsDuplicate">
              {{ $t("error.nik is duplicate") }}
            </span>
            <span class="error--badge" v-if="memberIsDuplicate">
              {{ $t("error.member is duplicate") }}
            </span>
          </div>
          <div class="error--badge-container -margin-top pulse--animation mt-1">
            <span class="error--badge" v-if="memberIsUser">
              {{ $t("error.user cannot be member") }}
            </span>
          </div>
          <div v-if="form.rombongan_organik">
            <v-row
              class="mt-3 relative"
              v-for="(rombongan, index) in rombonganOrganiks"
              :key="index"
            >
              <v-col cols="12" sm="12" md="5" class="pt-0 pb-xs-0">
                <v-autocomplete
                  :data-hint="$t('introjs.this is employee member selector')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-bind:search-input.sync="qSearchKaryawan"
                  :id="'rombongan_' + index"
                  @change="qSearchKaryawan = ''"
                  v-model="rombongan.selected"
                  :items="karyawanRombongan"
                  hide-no-data
                  hide-selected
                  item-text="nama"
                  item-value="nik_sap"
                  label="Karyawan (Peserta Rombongan)"
                  placeholder="Ketik NIK, Nama atau Jabatan"
                  :prepend-icon="iconSearch"
                  clearable
                  return-object
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.nama }}
                  </template>
                </v-autocomplete>
                <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      tabindex="-1"
                      v-bind:data-hint="
                        $t('introjs.this button is to remove member')
                      "
                      data-hintposition="middle-middle"
                      data-position="bottom-right-aligned"
                      v-bind:loading="axiosProcessing"
                      v-bind:disabled="axiosProcessing"
                      color="blue-grey"
                      class="ml-5 px-0 white--text rombongan--button"
                      @click="removeRombongan('x', index)"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon dark>
                        {{ iconDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.remove-member") }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="rombongan.selected && rombongan.selected.nama"
                cols="12"
                sm="6"
                md="2"
                class="auto--pl pt-0 pb-xs-0"
              >
                <p style="display: block">
                  <span
                    style="
                      font-size: 11px;
                      display: block;
                      margin-bottom: 0.2rem;
                    "
                    >NIK SAP</span
                  >
                  {{ rombongan.selected?.nik_sap }}
                </p>
              </v-col>
              <v-col
                v-if="rombongan.selected && rombongan.selected.nama"
                cols="12"
                sm="6"
                md="5"
                class="auto--pl pt-0 pb-xs-0"
              >
                <p style="display: block">
                  <span
                    style="
                      font-size: 11px;
                      display: block;
                      margin-bottom: 0.2rem;
                    "
                    >Jabatan/Bagian</span
                  >
                  {{ rombongan.selected?.nama_jabatan }}
                  <br />
                  {{ rombongan.selected?.nama_bagian }}
                  <br />
                  <strong>({{ rombongan.selected?.grade_sppd }})</strong>
                </p>
              </v-col>
            </v-row>

            <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind:data-hint="$t('introjs.this button is to add member')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-bind:loading="axiosProcessing"
                  v-bind:disabled="axiosProcessing"
                  color="blue-grey"
                  class="mb-3 ml-8 white--text px-0"
                  @click="addRombongan('x')"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon dark>
                    {{ iconAdd }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.add-member") }}</span>
            </v-tooltip>
          </div>
          <v-spacer class="border-b"></v-spacer>
          <v-row>
            <v-col cols="12" class="d-flex pt-0 pb-xs-0 flex">
              <v-checkbox
                :model-value="form.rombongan_non_organik"
                v-model="form.rombongan_non_organik"
                label="Rombongan Non Karyawan"
                color="primary"
                hide-details
                aria-checked="false"
                v-bind:data-hint="
                  $t('introjs.this checkbox for toggle non ptpn4 member')
                "
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
              ></v-checkbox>
              <span style="padding-top: 1.6rem; padding-left: 0.5rem"
                >(Atas Nama Kepala rombongan)</span
              >
            </v-col>
          </v-row>
          <div v-if="form.rombongan_non_organik">
            <v-row
              class="mt-3 relative"
              v-for="(rombongan, index) in rombonganNonOrganiks"
              :key="index"
            >
              <v-col cols="12" md="5" class="mt-3 pt-0 pb-xs-0">
                <v-text-field
                  :prepend-icon="iconEdit"
                  label="Nama Peserta"
                  v-model="rombongan.nama"
                ></v-text-field>
                <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      tabindex="-1"
                      v-bind:data-hint="
                        $t('introjs.this button is to remove member')
                      "
                      data-hintposition="middle-middle"
                      data-position="bottom-right-aligned"
                      v-bind:loading="axiosProcessing"
                      v-bind:disabled="axiosProcessing"
                      color="blue-grey"
                      class="ml-5 px-0 white--text rombongan--button"
                      @click="removeRombongan('non_organik', index)"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon dark>
                        {{ iconDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.remove-member") }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="3" class="auto--pl mt-3 pt-0 pb-xs-0">
                <v-combobox
                  clearable
                  hide-selected
                  label="Grade Karyawan"
                  v-model="rombongan.grade"
                  :items="gradeList"
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="3" class="auto--pl mt-3 pt-0 pb-xs-0">
                <v-combobox
                  clearable
                  hide-selected
                  label="Status Karyawan"
                  v-model="rombongan.status_karyawan"
                  :items="statusKaryawan"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind:data-hint="$t('introjs.this button is to add member')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-bind:loading="axiosProcessing"
                  v-bind:disabled="axiosProcessing"
                  color="blue-grey"
                  class="mb-3 ml-8 white--text px-0"
                  @click="addRombongan('non_organik')"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon dark>
                    {{ iconAdd }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.add-member") }}</span>
            </v-tooltip>
          </div>
        </v-card-text>
        <v-overlay v-bind:absolute="true" v-bind:value="editorIsWorking">
        </v-overlay>
        <v-overlay v-bind:value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
      <v-card v-if="false" style="height: 100% !important">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          width="100%"
          type="actions, article, list-item-three-line"
        >
        </v-skeleton-loader>
      </v-card>
    </v-dialog>
    <upline-selector
      v-bind:showUplineDialog="showUplineDialog"
      v-on:showHideUplineDialog="showHideUplineDialog"
      v-on:uplineSelected="uplineSelected"
    ></upline-selector>
  </div>
</template>

<style lang="scss">
.relative {
  position: relative;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.rombongan--button {
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
}

.v-dialog.v-dialog--active.v-dialog--persistent.create-general-memo-modal {
  height: 98vh !important;
  max-height: 100vh !important;

  .fr-element.fr-view {
    height: calc(100vh - 340px) !important;
    max-height: 100vh !important;
  }
}

@media only screen and (max-width: 767px) {
  .v-dialog.v-dialog--active.v-dialog--persistent.create-general-memo-modal {
    margin: 10px !important;

    .fr-element.fr-view {
      height: calc(100vh - 440px) !important;
      max-height: 100vh !important;
    }
  }
}

.width-max {
  margin: 10px !important;
}

.height-95vh {
  min-height: 98vh;
}

.border-b {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #dfdfdf;
}

.theme--dark .border-b {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #4c545d;
}

.font--error {
  color: rgb(223, 60, 60);
}

.theme--dark .font--error {
  color: rgb(238, 110, 110);
}

.error--badge-container {
  padding: 0.5rem 0rem 0.5rem 2.2rem;

  .error--badge {
    background-color: red;
    border-radius: 5px;
    padding: 0.3rem 0.5rem;
    font-size: 0.9em;
    font-weight: 600;
    color: #fff;
    width: 100%;
    display: block;
    opacity: 0;
    animation: errFadeIn 0.6s ease-in forwards;
  }
}

.theme--dark .error--badge {
  background-color: #b32d2d;
}

.-margin-top {
  margin-top: -1.5rem;
}

@keyframes errFadeIn {
  to {
    opacity: 1;
  }
}

.h-10 {
  height: 2.5rem;
}

.pulse--animation {
  animation: pulse--animation 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse--animation {
  50% {
    opacity: 0.3;
  }
}

@media screen and (max-width: 768px) {
  .auto--pl {
    padding-left: 3rem;
  }
}

@media screen and (max-width: 1920px) {
  .auto--pl {
    padding-left: auto;
  }
}
</style>
